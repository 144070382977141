import React, { useMemo } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { useAppSelector } from "../../hooks/ReduxHooks";
import { Seo } from "../../components/shared/Seo";

type DatenschutzParagraph = {
  text: string;
  variables?: {
    email?: string;
    primaryNumber?: string;
    jobsEmail?: string;
  };
  id?: string;
};

export const Datenschutz = () => {
  const { t } = useTranslation();
  const { email, primaryNumber } = useAppSelector((state) => state.contactInfo);

  const datenschutzParagraphs: DatenschutzParagraph[] = useMemo(
    () => [
      { text: "datenschutz.text1" },
      { text: "datenschutz.text2" },
      { text: "datenschutz.text3" },
      { text: "datenschutz.text4" },
      { text: "datenschutz.text5" },
      { text: "datenschutz.text6" },
      { text: "datenschutz.text7" },
      { text: "datenschutz.text8" },
      { text: "datenschutz.text9" },
      { text: "datenschutz.text10" },
      { text: "datenschutz.text11" },
      { text: "datenschutz.text12" },
      { text: "datenschutz.text13" },
      { text: "datenschutz.text14" },
      { text: "datenschutz.text15" },
      { text: "datenschutz.text16" },
      { text: "datenschutz.text17" },
      { text: "datenschutz.text18" },
      { text: "datenschutz.text19" },
      { text: "datenschutz.text20" },
      { text: "datenschutz.text21" },
      { text: "datenschutz.text22" },
      { text: "datenschutz.text23" },
      { text: "datenschutz.text24" },
      { text: "datenschutz.text25" },
      { text: "datenschutz.text26" },
      { text: "datenschutz.text27" },
      { text: "datenschutz.text28" },
      { text: "datenschutz.text29" },
      { text: "datenschutz.text30" },
      { text: "datenschutz.text31" },
      { text: "datenschutz.text32" },
      { text: "datenschutz.text33" },
      { text: "datenschutz.text34" },
      { text: "datenschutz.text35" },
      { text: "datenschutz.text36" },
      {
        text: "datenschutz.text37",
        variables: {
          email,
        },
      },
      { text: "datenschutz.text38" },
      { text: "datenschutz.text39" },
      { text: "datenschutz.text40", id: "cookies" },
      { text: "datenschutz.text41" },
      { text: "datenschutz.text42" },
      { text: "datenschutz.text43" },
      { text: "datenschutz.text44" },
      { text: "datenschutz.text45" },
      { text: "datenschutz.text46" },
      { text: "datenschutz.text47" },
      { text: "datenschutz.text48" },
      { text: "datenschutz.text49" },
      { text: "datenschutz.text50" },
      { text: "datenschutz.text51" },
      { text: "datenschutz.text52" },
      { text: "datenschutz.text53" },
      { text: "datenschutz.text54" },
      { text: "datenschutz.text55" },
      { text: "datenschutz.text56" },
      { text: "datenschutz.text57" },
      { text: "datenschutz.text58" },
      { text: "datenschutz.text59" },
      { text: "datenschutz.text60" },
      { text: "datenschutz.text61" },
      { text: "datenschutz.text62" },
      { text: "datenschutz.text63" },
      { text: "datenschutz.text64" },
      { text: "datenschutz.text65" },
      { text: "datenschutz.text66" },
      { text: "datenschutz.text67" },
      { text: "datenschutz.text68" },
      { text: "datenschutz.text69" },
      { text: "datenschutz.text70" },
      { text: "datenschutz.text71" },
      { text: "datenschutz.text72" },
      { text: "datenschutz.text73" },
      { text: "datenschutz.text74" },
      { text: "datenschutz.text75" },
      { text: "datenschutz.text76" },
      { text: "datenschutz.text77" },
      { text: "datenschutz.text78" },
      { text: "datenschutz.text79" },
      { text: "datenschutz.text80" },
      { text: "datenschutz.text81" },
      { text: "datenschutz.text82" },
      { text: "datenschutz.text83" },
      {
        text: "datenschutz.text84",
        variables: {
          email,
          primaryNumber,
        },
      },
    ],
    []
  );

  return (
    <Layout>
      <Seo title={t("datenschutz.page")} />
      <BgContainer>
        <Container>
          <PageTitle>{t("datenschutz.title")}</PageTitle>
        </Container>
      </BgContainer>
      <InfoBox>
        <MainText>
          {t("sieKönnenUnsere")}
          <Button to="#cookies">{t("cookieRichtlinien")}</Button>
          {t("lesenUnd")}
          <CookiesButton className="cky-banner-element">
            {t("ihreEinstellungen")}
          </CookiesButton>
        </MainText>
        {datenschutzParagraphs.map((el, i) => {
          const idProps = el.id ? { id: el.id } : {};
          return (
            <Text
              key={i}
              {...idProps}
              dangerouslySetInnerHTML={{
                __html: `${t(el.text, el.variables)}`,
              }}
            ></Text>
          );
        })}
      </InfoBox>
    </Layout>
  );
};

const MainText = styled("div", {
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "28px",
  margin: "0 0 20px",
  paddingBottom: 20,
  borderBottom: "1px solid $secondaryBackground",
  maxWidth: 810,
});

const CookiesButton = styled("span", {
  cursor: "pointer",
  color: "$accent",
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "28px",
  background: "transparent",
  border: "none",
});

const Button = styled(Link, {
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "28px",
});

const BgContainer = styled("div", {
  background: "$primaryBackground",
});

const Container = styled("div", {
  height: 180,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 16px",
  maxWidth: 1280,
  margin: "0 auto",
  "@md": {
    justifyContent: "start",
    padding: 0,
  },
});

const PageTitle = styled("h1", {
  margin: 0,
  color: "$heading",
  fontWeight: 700,
  fontSize: "36px",
  lineHeight: "44px",
  "@md": {
    fontSize: "48px",
    lineHeight: "56px",
  },
});

const InfoBox = styled("div", {
  margin: "48px 16px 80px",
  maxWidth: 1280,
  "@md": {
    margin: "48px auto 80px",
  },
});

const Text = styled("p", {
  margin: "0 0 20px",
  color: "$text",
  wordWrap: "break-word",
  "@md": {
    width: 810,
  },
});
